import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { Initial } from "react-initial";
import { useAuth } from "oidc-react";
import Menu from "@material-ui/core/Menu";

export default function LoginLogoutUserProfile() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const auth = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.clear();
    auth !== null && auth.signOut();
  };

  const handleLogin = () => {
    auth !== null && auth.signIn();
  };

  const menuItems = () => {
    if (auth && auth.userData) {
      return <MenuItem onClick={handleLogout}>Logout</MenuItem>;
    } else {
      return <MenuItem onClick={handleLogin}>Login</MenuItem>;
    }
  };

  return (
    <div>
      <Tooltip
        title={auth && auth.userData && auth.userData.profile.name ? auth.userData.profile.name : "please login"}>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <Avatar alt={auth && auth.userData ? auth.userData.profile.name : ""}>
            {auth && auth.userData ? (
              <Initial
                name={auth && auth.userData ? auth.userData.profile.name : "*"}
                useWords={true}
                charCount={2}
                width={50}
                height={50}
                textColor="#3f51b5"
                fontSize={15}
                color="#F3F3F3"
              />
            ) : (
              <PersonIcon />
            )}
          </Avatar>
        </Button>
      </Tooltip>
      <Menu
        id="simple-menu"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        {menuItems()}
      </Menu>
    </div>
  );
}
