import { CSVLink } from "react-csv";
import { IProtocolRuntime, IProtocolStack } from "./model";
import {
  NumberFormatter,
  createProtocolFilter,
  pad,
  showProtocol,
  toDateTimeFormat,
  toDateTimeFormatPlain,
  toHHMM,
} from "./Utils";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { loadProtocol } from "./restFacade";
import { zebStyles as parentZebStyles } from "./zebstyles";
import { useAuth } from "oidc-react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import useAPIError from "./Dialogs/error/useAPIError";

const useStyles = makeStyles((theme: Theme) => ({
  ...parentZebStyles(theme),
  mainHeader: {
    "& > *": {
      //borderBottom: 'unset',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
  },
  mainRow: {
    "& > *": {
      borderBottom: "unset",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0),
    },
  },
  mainRowSecond: {
    "& > *": {
      borderBottom: "unset",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
  },
  detailRow: {
    "& > *": {
      borderBottom: "unset",
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  cell: {
    padding: theme.spacing(1),
    margin: 0,
    border: 0,
  },
  formControlYear: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(10),
    marginBottom: theme.spacing(4),
    minWidth: 120,
  },
  formControlMonth: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
    minWidth: 120,
  },
  formControlCSVExport: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  csvExportLink: {
    marginTop: theme.spacing(0),
    textDecoration: "none",
    color: "#0072C6",
  },
  paper: {
    padding: 0,
  },
}));

//row with one stack and detailows with runtimes
function Row(props: { index: number; row: IProtocolStack }) {
  const [rowDetailOpen, setRowDetailOpen] = useState(getRowOpend);
  const classes = useStyles();

  function getRowOpend(): boolean {
    const result = localStorage.getItem("protocolrow_" + props.index);

    if (result === null) {
      localStorage.setItem("protocolrow_" + props.index, "false");
      return false;
    } else {
      return result === "true" ? true : false;
    }
  }

  function handleOpenDetail() {
    setRowDetailOpen(!rowDetailOpen);
    localStorage.setItem("protocolrow_" + props.index, (!rowDetailOpen).toString());
  }

  const rowDetail = props.row.runtimes.map((detailRow: IProtocolRuntime, index) => (
    <TableRow className={classes.mainRowSecond} key={index}>
      <TableCell align="left"></TableCell>
      <TableCell align="left"></TableCell>
      <TableCell align="left">{toDateTimeFormat(new Date(Date.parse(detailRow.starttime)))}</TableCell>
      <TableCell align="left">{detailRow.startuser}</TableCell>
      <TableCell align="left">{toDateTimeFormat(new Date(Date.parse(detailRow.stoptime)))}</TableCell>
      <TableCell align="left">{detailRow.stopuser}</TableCell>
      <TableCell align="right">
        <Tooltip title={toHHMM(detailRow.durationhours * 3600)} placement="right">
          <div>{NumberFormatter.format(detailRow.durationhours)} h</div>
        </Tooltip>
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  ));

  return (
    <React.Fragment>
      <TableRow className={classes.mainRow}>
        <TableCell align="left">
          <IconButton aria-label="expand row" size="small" onClick={() => handleOpenDetail()}>
            {rowDetailOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{props.row.stackdisplayname}</TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="right" style={{ fontWeight: "bold" }}>
          <Tooltip title={toHHMM(props.row.runtimessumhours * 3600)} placement="right">
            <div>{NumberFormatter.format(props.row.runtimessumhours)} h</div>
          </Tooltip>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      {rowDetailOpen ? rowDetail : null}
    </React.Fragment>
  );
}

function determineSumOverall(stacks: IProtocolStack[]): number {
  let sum = 0 as number;
  stacks.forEach((stack) => {
    sum = sum + stack.runtimessumhours;
  });
  return sum;
}

function convertTofilterDate(year: string, month: string, day: string): string {
  const y = Number(year);
  const m = Number(month);
  let d = Number(day);

  if (d >= 28) {
    //correct last day of month
    const date = new Date(y, m, 0);
    d = Number(date.getDate());
  }

  const result = year + pad(m, 2) + pad(d, 2);

  console.log(result);

  return result;
}

export default function Journal() {
  const classes = useStyles();
  const { addError } = useAPIError();

  const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear().toString());
  const [selectedMonth, setSelectedMonth] = React.useState((new Date().getMonth() + 1).toString());

  const [rows, setRows] = useState<IProtocolStack[]>([]);

  const [csvData, setCsvData] = useState<string[][]>([]);

  const [waitingLoading, setWaitingLoading] = useState(false);

  const [sumOverall, setSumOverall] = useState(0);
  const auth = useAuth();

  function loadProtocolData(from: string, to: string) {
    setWaitingLoading(true);

    const emptyArray = [] as IProtocolStack[];
    setRows(emptyArray);
    setSumOverall(0);

    loadProtocol(createProtocolFilter(from, to, []), auth)
      .then((data) => {
        if (data != null) {
          setRows(data);
          console.log(data);
          setSumOverall(determineSumOverall(data));
          setCsvData(generateCSVData(data));
        }
      })
      .catch((error) => {
        addError(error.message, "error");
      })
      .finally(() => setWaitingLoading(false));
  }

  useEffect(() => {
    if (auth && auth.userData) {
      loadProtocolData(
        convertTofilterDate(selectedYear, selectedMonth, "01"),
        convertTofilterDate(selectedYear, selectedMonth, "31")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  function generateCSVData(stacks: IProtocolStack[]): string[][] {
    //setWaitingLoading(true);

    const result: string[][] = [["Environment", "Start Time", "Start User", "Stop Time", "Stop User", "Running hours"]];

    /*
    loadProtocol(createProtocolFilter(convertTofilterDate(selectYear, "01", "01"), convertTofilterDate(selectYear, "12", "31"), [])).then((stacks) => {
      if (stacks != null) {
      */
    stacks.forEach((stack) => {
      stack.runtimes.forEach((runtime) => {
        const row: string[] = [];
        row.push(stack.stackdisplayname);
        row.push(toDateTimeFormat(new Date(Date.parse(runtime.starttime))));
        row.push(runtime.startuser);
        row.push(toDateTimeFormat(new Date(Date.parse(runtime.stoptime))));
        row.push(runtime.stopuser);
        row.push(NumberFormatter.format(runtime.durationhours));
        result.push(row);
      });
    });

    /*
      }
    }).catch((data) => {
      console.error("Error loading environments for protocol");
    }).finally(() => {
      setWaitingLoading(false)
    });
  */
    return result;
  }

  const handleChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedYear(event.target.value as string);
    loadProtocolData(
      convertTofilterDate(event.target.value as string, selectedMonth, "01"),
      convertTofilterDate(event.target.value as string, selectedMonth, "31")
    );
  };

  const handleChangeMonth = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedMonth(event.target.value as string);
    loadProtocolData(
      convertTofilterDate(selectedYear, event.target.value as string, "01"),
      convertTofilterDate(selectedYear, event.target.value as string, "31")
    );
  };

  const csvExportFileName = () => {
    const now = new Date();
    return "EnvironmentJournal_" + selectedYear + selectedMonth + "_" + toDateTimeFormatPlain(now) + ".csv";
  };

  const currentyear = new Date().getFullYear() as number;

  if (!auth || !auth.userData) {
    return <div>Please log in</div>;
  }
  if (!showProtocol(auth)) {
    return <div>You do not have the necessary rights</div>;
  }

  return (
    <Paper>
      <FormControl className={classes.formControlYear}>
        <InputLabel id="label-year">Year</InputLabel>
        <Select
          labelId="label-year"
          id="demo-simple-select-outlined"
          value={selectedYear}
          onChange={handleChangeYear}
          label="Year">
          <MenuItem value={currentyear}>{currentyear}</MenuItem>
          <MenuItem value={currentyear - 1}>{currentyear - 1}</MenuItem>
          <MenuItem value={currentyear - 2}>{currentyear - 2}</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControlMonth}>
        <InputLabel id="label-month">Month</InputLabel>
        <Select
          labelId="label-month"
          id="demo-simple-select-outlined"
          value={selectedMonth}
          onChange={handleChangeMonth}
          label="Month">
          <MenuItem value={1}>January</MenuItem>
          <MenuItem value={2}>February</MenuItem>
          <MenuItem value={3}>March</MenuItem>
          <MenuItem value={4}>April</MenuItem>
          <MenuItem value={5}>May</MenuItem>
          <MenuItem value={6}>June</MenuItem>
          <MenuItem value={7}>July</MenuItem>
          <MenuItem value={8}>August</MenuItem>
          <MenuItem value={9}>September</MenuItem>
          <MenuItem value={10}>October</MenuItem>
          <MenuItem value={11}>November</MenuItem>
          <MenuItem value={12}>December</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControlCSVExport}>
        <CSVLink data={csvData} separator={";"} className={classes.csvExportLink} filename={csvExportFileName()}>
          <Button className={classes.iconButton} startIcon={<GetAppIcon />}>
            {"CSV"}
          </Button>
        </CSVLink>
      </FormControl>

      {/*
      <IconButton><RefreshIcon className={classes.iconButton} /></IconButton>
*/}
      <br></br>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.mainHeader}>
              <TableCell align="center" style={{ width: 20 }}>
                {waitingLoading ? <CircularProgress className={classes.iconButton} size={20} /> : ""}
              </TableCell>
              <TableCell align="left" style={{ width: 300 }}>
                Environment
              </TableCell>
              <TableCell align="left" style={{ width: 200 }}>
                Start
              </TableCell>
              <TableCell align="left" style={{ width: 200 }}>
                Start User
              </TableCell>
              <TableCell align="left" style={{ width: 200 }}>
                Stop
              </TableCell>
              <TableCell align="left" style={{ width: 200 }}>
                Stop User
              </TableCell>
              <TableCell align="right" style={{ width: 200 }}>
                Running Hours
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {rows.map((row, index) => (
                <Row index={index} key={row.stackid} row={row} />
              ))}
            </>

            <TableRow className={classes.mainRow}>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="right" style={{ width: 200, fontWeight: "bold" }}>
                <hr></hr>
                <Tooltip title={toHHMM(sumOverall * 3600)} placement="right">
                  <div>{NumberFormatter.format(sumOverall)} h</div>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br></br>
    </Paper>
  );
}
