import { $enum } from "ts-enum-util";

export enum RunState {
  Stopped = 0,
  Running = 1,
}

export enum Weekdays {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum INSTANCETYPES {
  RDS = "RDS",
  EC2 = "EC2",
  APPSTREAM = "APPSTREAM-FLEET",
}

export const INSTANCETYPE_DEFAULT = INSTANCETYPES.EC2;

export function instanceTypeValueByKey(pType: INSTANCETYPES): string {
  return $enum(INSTANCETYPES).asValueOrThrow(pType);
}

export interface IEnvironmentStates {
  service_stacks: IEnvironmentState[];
  message: string;
  timestamp: string;
}

export interface IStartStopConfig {
  start: string;
  stop: string;
  cron: string;
  enabled: boolean;
  description: string;
  index: number;
}

export interface IStartStopConfigUIFriendly {
  autoStartEnabled: boolean;
  autoStopEnabled: boolean;
  startTime: string;
  stopTime: string;
  startDays: boolean[]; //0=Sun
  stopDays: boolean[]; //0=Sun
  indexstart: number;
  indexstop: number;
}

export interface IServiceState {
  servicename: string;
  servicetype: string;
  servicestate: string;
}

export interface IEnvironmentState {
  servicestackid: string;
  targetstate: string;
  currentstate: string;
  displayname: string;
  description: string;
  visibilityrole: string;
  servicestates: IServiceState[];
  schedules: IStartStopConfig[];
  laststart: string;
  laststop: string;
  startedby: string;
  stoppedby: string;
  message: string;
}

//administration

export interface IEnvironments {
  servicestacks: IServiceStack[];
  message: string;
  timestamp: string;
}

export interface IServiceStack {
  displayname: string;
  servicestackid: string;
  services: IAWSService[];
}

export interface IAWSService {
  servicename: string;
  servicetype: string;
}

export function createAWSService(servicename: string, servicetype: string): IAWSService {
  return {
    servicename,
    servicetype,
  };
}

/*
export function createDummyEnvironment(
  id: string,
  displayName: string,
  instances: IAWSInstance[],
): IEnvironment {
  return {
    id,
    displayName,
    instances,
  }
}
*/

/*


export const environmentStateDummyData = [
  createEnvironmentStateDummyData('zeb.control Production', "running", new Date(2020, 5, 12, 8, 0), "System", new Date(2020, 5, 12, 8, 0),
    "System", true, ["08:00", "08:00", "08:00", "", "", "", ""], true, ["18:00", "18:00", "18:00", "", "", "", ""]),
  createEnvironmentStateDummyData('zeb.control Production Clients', "running", new Date(2020, 5, 12, 8, 0), "System", new Date(2020, 5, 12, 8, 0),
    "System", true, ["08:00", "08:00", "08:00", "", "", "", ""], true, ["18:00", "18:00", "18:00", "", "", "", ""]),
  createEnvironmentStateDummyData('zeb.control Test', "stopped", new Date(2020, 5, 10, 8, 0), "Ralf", new Date(2020, 5, 10, 18, 0),
    "Ralf", false, ["", "", "", "", "", "", ""], false, ["", "", "", "", "", "", ""]),
  createEnvironmentStateDummyData('zeb.control Test Clients', "stopped", new Date(2020, 5, 10, 8, 0), "Ralf", new Date(2020, 5, 10, 18, 0),
    "Ralf", false, ["", "", "", "", "", "", ""], false, ["", "", "", "", "", "", ""]),
];

function createEnvironmentStateDummyData(
  displayName: string,
  environment_state: string,
  lastStarted: Date,
  lastStartedBy: string,
  lastStopped: Date,
  lastStoppedBy: string,
  autostartEnabled: boolean,
  autostartdays: string[],
  autostopEnabled: boolean,
  autostopdays: string[],
): IEnvironmentState {
  return {

    servicestackid: displayName,
    targetstate: environment_state,
    currentstate: environment_state,
    stack_label: displayName,
    stack_description: displayName,
    visibility_role: "admin",
    servicestates: [],
    start_stop_schedule_config: [],
    lastStarted: lastStarted.toString(),
    lastStartedBy,
    lastStopped: lastStopped.toString(),
    lastStoppedBy,
    message: "",

  };
}

*/

export interface ILock {
  timestamp: string;
  message: string;
  locksessionid: string;
  locktime: string;
  lockuser: string;
  mylock: boolean;
  havelock: boolean;
  canlock: boolean;
}

export interface IRestResult {
  ok: boolean;
  message: string;
}

export function restResult(ok: boolean, message: string): IRestResult {
  return { ok, message };
}

export interface IProtocolRuntime {
  duration: string;
  starttime: string;
  startuser: string;
  stoptime: string;
  stopuser: string;
  durationhours: number;
}
export interface IProtocolStack {
  stackid: string;
  stackdisplayname: string;
  runtimes: IProtocolRuntime[];
  runtimessumhours: number;
}
export interface IProtocolStacks {
  servicestacks: IProtocolStack[];
}
export interface IProtocolFilter {
  lowerbound: string;
  upperbound: string;
  stackids: string[];
}
