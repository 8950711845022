import axios, { AxiosInstance } from "axios";

import { AuthContextProps } from "oidc-react/build/src/AuthContextInterface";
import {
  IEnvironmentState,
  IEnvironmentStates,
  IEnvironments,
  IProtocolFilter,
  IProtocolStack,
  IRestResult,
  IServiceStack,
  IStartStopConfig,
  restResult,
} from "./model";
import { stringfyomitkeys } from "./Utils";

const axiosinstance = axios.create({
  timeout: 10000,
  baseURL: "https://api-envmgr.986464814838.zeb-it.de",
});

const headers = {
  "Content-Type": "application/json",
};

function defineHeaderAxios(auth: AuthContextProps | null, axiosInstance: AxiosInstance) {
  if (auth === null) {
    console.warn("Auth context is null");
  } else {
    if (auth.userData) {
      const acessToken = auth.userData?.access_token;
      //console.info("access-token:" + acessToken);

      axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + acessToken;
    } else {
      console.warn("Auth is set but userdata is null");
    }

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        const originalRequest = error.config;

        console.warn("first attempt to execute request had errors: " + error.response.status);

        if (error.response.status === 401 && !originalRequest._retry) {
          console.debug("re-authenticate to get ticket");
          originalRequest._retry = true;

          auth.signIn();

          if (auth.userData) {
            console.debug("re-authenticate was successfull");
            const acessToken = auth.userData?.access_token;
            //console.info("access-token:" + acessToken);

            console.debug("second attempt....");
            axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + acessToken;
            return axiosInstance(originalRequest);
          }
        }
        console.warn("error while handling requests. unable to get token for second request");
        return Promise.reject(error);
      }
    );
  }
}

export async function getAllEnvironmentState(auth: AuthContextProps | null): Promise<IEnvironmentStates | null> {
  try {
    defineHeaderAxios(auth, axiosinstance);
    const response = await axiosinstance.get("/envctrl");
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getEnvironmentState(
  id: string,
  auth: AuthContextProps | null
): Promise<IEnvironmentState | null> {
  try {
    defineHeaderAxios(auth, axiosinstance);
    const response = await axiosinstance.get("/envctrl/" + id);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getAllEnvironments(auth: AuthContextProps | null): Promise<IEnvironments | null> {
  try {
    defineHeaderAxios(auth, axiosinstance);
    const response = await axiosinstance.get("/envmgr");

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getEnvironment(id: string, auth: AuthContextProps | null): Promise<IServiceStack | null> {
  try {
    defineHeaderAxios(auth, axiosinstance);
    const response = await axiosinstance.get("/envmgr/" + id);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function createEnvironment(
  envId: string,
  displayName: string,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  const body = '{"stack_id":"' + envId + '", "stack_label":"' + displayName + '"}';

  try {
    //aquireLock
    defineHeaderAxios(auth, axiosinstance);
    const responseLock = await axiosinstance.post("/envmgr/editlock");

    if (responseLock.status === 200) {
      const response = await axiosinstance.post("/envmgr", body, {
        headers: headers,
      });
      if (response.status !== 200) {
        throw restResult(false, "Error while creating environment: " + response);
      }
    } else {
      return restResult(false, "Could not aquire lock. Please try again later");
    }
  } catch (error) {
    console.error(error);
    throw restResult(false, "Error while creating service: " + error);
  }

  return restResult(true, "Successfully created environment");
}

export async function deleteEnvironment(
  envId: string,
  displayname: string,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  try {
    //aquireLock
    defineHeaderAxios(auth, axiosinstance);
    const responseLock = await axiosinstance.post("/envmgr/editlock");

    if (responseLock.status === 200) {
      const response = await axiosinstance.delete("/envmgr/" + envId);
      if (response.status !== 200) {
        throw restResult(false, "Error deleting environment");
      }
    } else {
      throw restResult(false, "Could not aquire lock. Please try again later");
    }
  } catch (error) {
    console.error(error);
  }

  return restResult(true, 'Successfully deleted environment "' + displayname + '"');
}

export async function stopEnvironment(
  envId: string,
  displayname: string,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  try {
    defineHeaderAxios(auth, axiosinstance);
    const response = await axiosinstance.put("/envctrl/" + envId + "/stop");

    if (response.status !== 200) {
      throw restResult(false, "Error stopping environment. " + response.data.message);
    }
  } catch (error) {
    console.error(error);
    throw restResult(false, "Error while stopping environment: " + error);
  }

  return restResult(true, 'Stopping environment "' + displayname + '". This may take a while.');
}

export async function startEnvironment(
  envId: string,
  displayname: string,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  try {
    defineHeaderAxios(auth, axiosinstance);
    const response = await axiosinstance.put("/envctrl/" + envId + "/start");

    if (response.status !== 200) {
      throw restResult(false, "Error starting environment. " + response.data.message);
    }
  } catch (error) {
    console.error(error);
    throw restResult(false, "Error while starting environment: " + error);
  }

  return restResult(true, 'Starting environment "' + displayname + '". This may take a while. ');
}

export async function createServiceForEnvironment(
  envId: string,
  serviceName: string,
  instanceType: string,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  const body = '{"service_name":"' + serviceName + '", "service_type":"' + instanceType + '"}';

  try {
    //aquireLock
    defineHeaderAxios(auth, axiosinstance);
    const responseLock = await axiosinstance.post("/envmgr/editlock");

    if (responseLock.status === 200) {
      //FIXME headers
      const response = await axiosinstance.post("/envmgr/" + envId, body, {
        headers: headers,
      });
      if (response.status !== 200) {
        throw restResult(false, "Error while creating service: " + response);
      }
    } else {
      return restResult(false, "Could not aquire lock. Please try again later");
    }
  } catch (error) {
    console.error(error);
    throw restResult(false, "Error while creating service: " + error);
  }

  return restResult(true, "Successfully created service");
}

export async function deleteService(
  envId: string,
  serviceName: string,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  try {
    //aquireLock
    defineHeaderAxios(auth, axiosinstance);
    const responseLock = await axiosinstance.post("/envmgr/editlock");

    if (responseLock.status === 200) {
      const response = await axiosinstance.delete("/envmgr/" + envId + "/" + serviceName);
      if (response.status !== 200) {
        throw restResult(false, "Error while creating service: " + response);
      }
    } else {
      throw restResult(false, "Could not aquire lock. Please try again later");
    }
  } catch (error) {
    console.error(error);
  }

  return restResult(true, "Successfully deleted service");
}

export async function createStartStopConfig(
  envId: string,
  startstopConfig: IStartStopConfig,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  const body = JSON.stringify(stringfyomitkeys(startstopConfig, ["index"]));

  //aquireLock
  defineHeaderAxios(auth, axiosinstance);
  const responseLock = await axiosinstance.post("/envmgr/editlock").catch((responseLock) => {
    throw restResult(false, "Error while creating start/stop config: " + responseLock.data);
  });

  if (responseLock.status === 200) {
    await axiosinstance
      .post("/envmgr/" + envId + "/schedules/" + startstopConfig.index, body, {
        //const response = await axiosinstance.post("/envmgr/" + envId + "/schedules/" + (startstopConfig.index >0 ? startstopConfig.index -1 : 0), body, {
        headers: headers,
      })
      .then((response) => {
        if (response.status !== 200) {
          throw restResult(false, "Error while creating service: " + response);
        }
        console.debug("createStartStopConfig: " + response.data.message);
      })
      .catch((response) => {
        console.error("Error createStartStopConfig" + response);
        throw restResult(false, "Error while creating service: " + response.data);
      });
  } else {
    return restResult(false, "Could not aquire lock. Please try again later");
  }

  return restResult(true, "Successfully created service");
}

export async function updateStartStopConfig(
  envId: string,
  startstopConfig: IStartStopConfig,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  const body = JSON.stringify(stringfyomitkeys(startstopConfig, ["index"]));

  //aquireLock
  defineHeaderAxios(auth, axiosinstance);
  const responseLock = await axiosinstance.post("/envmgr/editlock").catch((responseLock) => {
    throw restResult(false, "Error while updating start/stop config (Lock): " + responseLock.data);
  });

  if (responseLock.status === 200) {
    console.debug("Update startstopconfig. Index: " + startstopConfig.index + " " + body);
    const response = await axiosinstance
      .put("/envmgr/" + envId + "/schedules/" + startstopConfig.index, body, {
        headers: headers,
      })
      .catch((response) => {
        console.debug("Error updateStartStopConfig: " + response);
        throw restResult(false, "Error while updating start/stop config: " + response.response.data.exception);
      });
    if (response.status !== 200) {
      throw restResult(false, "Error updateing scheduler config");
    }
  } else {
    return restResult(false, "Could not aquire lock. Please try again later");
  }

  return restResult(true, "Successfully created scheduler config");
}

export async function loadProtocol(
  filter: IProtocolFilter,
  auth: AuthContextProps | null
): Promise<IProtocolStack[] | null> {
  try {
    defineHeaderAxios(auth, axiosinstance);

    const body = JSON.stringify(filter);
    const coeff = 1000 * 60 * 1; //for round to one minute

    const responseprot = await axiosinstance.post("/envrep", body, {
      headers: headers,
    });

    //set displaynames
    if (responseprot.data.stacks.length > 0) {
      const stacks = responseprot.data.stacks as IProtocolStack[];

      stacks.forEach((stack) => {
        //determine runtimes
        stack.runtimessumhours = 0;
        if (stack.runtimes.length > 0) {
          stack.runtimes.forEach((runtime) => {
            if (
              runtime.starttime !== null &&
              runtime.starttime !== undefined &&
              runtime.stoptime !== null &&
              runtime.stoptime !== undefined
            ) {
              let start = new Date(Date.parse(runtime.starttime));
              let stop = new Date(Date.parse(runtime.stoptime));

              start = new Date(Math.round(start.getTime() / coeff) * coeff);
              stop = new Date(Math.round(stop.getTime() / coeff) * coeff);

              runtime.starttime = start.toUTCString();
              runtime.stoptime = stop.toUTCString();

              const diffTimeHours = (Math.abs(stop.getTime() - start.getTime()) / (1000 * 60 * 60)) as number;
              //const diffTimeSeconds = (Math.abs(stop.getTime() - start.getTime()) / 1000) as number;
              runtime.durationhours = diffTimeHours;
              //var diffhours = Math.ceil(diffTime / (1000 * 60 * 60));
              stack.runtimessumhours = stack.runtimessumhours + diffTimeHours;
            }
          });
        }
      });

      stacks.sort(function (a, b) {
        const nameA = a.stackdisplayname.toUpperCase(); // Groß-/Kleinschreibung ignorieren
        const nameB = b.stackdisplayname.toUpperCase(); // Groß-/Kleinschreibung ignorieren
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // Namen müssen gleich sein
        return 0;
      });
      return stacks;
    }
  } catch (error) {
    const msg = error.response.data.message + ". " + error.response.data.exception;
    console.error(msg);
    throw restResult(false, "Error while loading protocol data: " + msg);
  }

  return null;
}
