import { APIErrorProvider } from "./Dialogs/error/APIErrorProvider";
import { SnackbarProvider } from "notistack";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { zebStyles as parentZebStyles } from "./zebstyles";
import { showAdministration, showProtocol, showStatus } from "./Utils";
import { useAuth } from "oidc-react";
import Administration from "./Administration";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import EnvironmentStates from "./EnvironmentStates";
import ErrorDialog from "./Dialogs/error/ErrorDialog";
import Journal from "./Journal";
import LoginLogoutUserProfile from "./LoginLogoutUserProfile";
import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ZebLogoIcon from "./ZebLogoIcon";
import preval from "preval.macro";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  ...parentZebStyles(theme),

  root: {
    flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    backgroundColor: "#F3F3F3",
  },
  appbar: {
    backgroundColor: "#0072C6",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function getCurrentTab(): number {
  const tab = localStorage.getItem("currenttab");
  return tab === null ? 0 : parseInt(tab);
}

export default function App() {
  const classes = useStyles();
  const [value, setValue] = React.useState(getCurrentTab());

  const auth = useAuth();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    localStorage.setItem("currenttab", "" + newValue);
    setValue(newValue);
  };

  const MyTabs = () => {
    if (auth && auth.userData) {
      return (
        <Tabs
          value={value}
          onChange={handleTabChange}
          className={classes.tabs}
          TabIndicatorProps={{ style: { background: "#0072C6" } }}>
          {showStatus(auth) ? <Tab label="Status" {...a11yProps(0)} /> : <div></div>}
          {showProtocol(auth) ? <Tab label="Protocol" {...a11yProps(1)} /> : <div></div>}
          {showAdministration(auth) ? <Tab label="Administration" {...a11yProps(2)} /> : <div></div>}
        </Tabs>
      );
    } else {
      return <p></p>;
    }
  };

  return (
    <div>
      <SnackbarProvider maxSnack={3}>
        <APIErrorProvider>
          <AppBar position="static" className={classes.appbar}>
            <Toolbar>
              <ZebLogoIcon size={2} className={classes.logo} />
              <Tooltip title={preval`module.exports = new Date().toLocaleString();`}>
                <Typography variant="h6" className={classes.title}>
                  CLOUD EnvironmentManager
                </Typography>
              </Tooltip>

              <LoginLogoutUserProfile />
            </Toolbar>
            <MyTabs />
          </AppBar>

          <TabPanel key={0} value={value} index={0}>
            <EnvironmentStates />
          </TabPanel>
          <TabPanel key={1} value={value} index={1}>
            <Journal />
          </TabPanel>
          <TabPanel key={2} value={value} index={2}>
            <Administration />
          </TabPanel>
          <ErrorDialog></ErrorDialog>
        </APIErrorProvider>
      </SnackbarProvider>
    </div>
  );
}
