// src/common/providers/APIErrorProvider/index.js
import React, { useCallback, useState } from "react";

export interface IError {
  message: string;
  type: string;
  showDialog: boolean;
  addError(message: string, type: string): void;
  removeError(): void;
}

export const APIErrorContext = React.createContext({} as IError);

type Props = {
  children: React.ReactNode;
};

export const APIErrorProvider = ({ children }: Props) => {
  const defaultContext = {
    message: "",
    type: "error",
    showDialog: false,
    addError: useCallback(
      (message: string, type: string) => addError(message, type),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    removeError: useCallback(
      () => removeError(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
  } as IError;

  const [error, setError] = useState(defaultContext);

  const addError = (message: string, type: string) =>
    setError({ message, type, showDialog: true, addError, removeError } as IError);

  const removeError = () => {
    const newState = {} as IError;
    Object.assign(newState, error);
    newState.showDialog = false;
    setError(newState);
  };

  return <APIErrorContext.Provider value={error}>{children}</APIErrorContext.Provider>;
};
