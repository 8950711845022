import { AuthContextProps } from "oidc-react/build/src/AuthContextInterface";
import { IProtocolFilter, IServiceStack, IStartStopConfig, IStartStopConfigUIFriendly, Weekdays } from "./model";
import { useEffect, useRef } from "react";
import Moment from "moment";
import moment from "moment";
import url from "url";

const STARTCONFIG = "startconfig";
const STOPCONFIG = "stopconfig";

export const EuroFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

export const NumberFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export function toDateTimeFormat(d: Date): string {
  Moment.locale("de");
  //return Moment(d).format('DD.MM.YYYY HH:mm');
  return Moment(d).format("DD.MM.YYYY HH:mm");
}

export function toDateTimeFormatPlain(d: Date): string {
  Moment.locale("de");
  return Moment(d).format("DDMMYYYYHHmm");
}

export function useIntervall(callback: any, delay: number) {
  const savedCallback = useRef(defaultFunction);

  function defaultFunction() {
    //doNothing()
  }

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}

export function createEmptyStartStopConfig(config: string, index: number): IStartStopConfig {
  return {
    start: "", //(config == 'start' ? '' : 'none'),
    stop: "", //(config == 'stop' ? '' : 'none'),
    cron: "",
    enabled: false,
    description: config === "start" ? STARTCONFIG : STOPCONFIG,
    index: index,
  };
}

export function createStartStopConfigBackendFriendly(
  startStopConfigUI: IStartStopConfigUIFriendly
): IStartStopConfig[] {
  const startConfig = {} as IStartStopConfig;
  const stopConfig = {} as IStartStopConfig;

  startConfig.start = startStopConfigUI.startTime;
  startConfig.stop = "none";
  startConfig.enabled = startStopConfigUI.autoStartEnabled;
  startConfig.cron = createCronString(startStopConfigUI.startDays);
  startConfig.description = STARTCONFIG;
  startConfig.index = startStopConfigUI.indexstart;

  stopConfig.start = "none";
  stopConfig.stop = startStopConfigUI.stopTime;
  stopConfig.enabled = startStopConfigUI.autoStopEnabled;
  stopConfig.cron = createCronString(startStopConfigUI.stopDays);
  stopConfig.description = STOPCONFIG;
  stopConfig.index = startStopConfigUI.indexstop;

  return [startConfig, stopConfig];
}

function createCronString(days: boolean[]): string {
  let cron = "* * * * ";
  let i = 0;

  if (days[Weekdays.Sunday]) {
    cron = cron + "0";
    i = i + 1;
  }
  if (days[Weekdays.Monday]) {
    if (i > 0) {
      cron = cron + ",";
    }
    cron = cron + "1";
    i = i + 1;
  }
  if (days[Weekdays.Tuesday]) {
    if (i > 0) {
      cron = cron + ",";
    }
    cron = cron + "2";
    i = i + 1;
  }
  if (days[Weekdays.Wednesday]) {
    if (i > 0) {
      cron = cron + ",";
    }
    cron = cron + "3";
    i = i + 1;
  }
  if (days[Weekdays.Thursday]) {
    if (i > 0) {
      cron = cron + ",";
    }
    cron = cron + "4";
    i = i + 1;
  }
  if (days[Weekdays.Friday]) {
    if (i > 0) {
      cron = cron + ",";
    }
    cron = cron + "5";
    i = i + 1;
  }
  if (days[Weekdays.Saturday]) {
    if (i > 0) {
      cron = cron + ",";
    }
    cron = cron + "6";
    i = i + 1;
  }
  return cron;
}

export function createStartStopConfigUIFriendly(
  envid: string,
  startStopConfigArray: IStartStopConfig[]
  //createIfNotExist: boolean
): IStartStopConfigUIFriendly {
  let startDays = [false, false, false, false, false, false, false];
  let stopDays = [false, false, false, false, false, false, false];

  let startConfig = createEmptyStartStopConfig("start", -1);
  let stopConfig = createEmptyStartStopConfig("stop", -1);

  let i = 0;

  //find the start and stop config in array. if config not available, add behind...
  startStopConfigArray.forEach((startstopconfig: IStartStopConfig) => {
    //startStopConfigArray.map((startstopconfig: IStartStopConfig, index) => {

    if (startstopconfig.description === STARTCONFIG) {
      startConfig = startstopconfig;
      startConfig.index = i;
    }
    if (startstopconfig.description === STOPCONFIG) {
      stopConfig = startstopconfig;
      stopConfig.index = i;
    }
    i = i + 1;
  });

  /*
  if (createIfNotExist && startConfig.index < 0) {
    startConfig.index = i;
    createStartStopConfig(envid, startConfig);
    i = i + 1;
  }

  if (createIfNotExist && stopConfig.index < 0) {
    stopConfig.index = i;
    createStartStopConfig(envid, stopConfig);
  }
*/

  startDays = createAutoStartDaysFromCron(startConfig.cron);
  stopDays = createAutoStartDaysFromCron(stopConfig.cron);

  return {
    autoStartEnabled: startConfig.enabled,
    autoStopEnabled: stopConfig.enabled,
    startTime: startConfig.start,
    stopTime: stopConfig.stop,
    startDays: startDays,
    stopDays: stopDays,
    indexstart: startConfig.index,
    indexstop: stopConfig.index,
  };
}

function createAutoStartDaysFromCron(cron: string): boolean[] {
  let days = "";

  if (cron !== null && cron !== "" && cron.includes(" ")) {
    const parts = cron.split(" ");

    if (parts.length > 4) {
      days = parts[4];
    }
  }

  return [
    days.includes("0") || days.includes("Sun"),
    days.includes("1") || days.includes("Mon"),
    days.includes("2") || days.includes("Tue"),
    days.includes("3") || days.includes("Wed"),
    days.includes("4") || days.includes("Thu"),
    days.includes("5") || days.includes("Fri"),
    days.includes("6") || days.includes("Sat"),
  ];
}

export function stringfyomitkeys(obj: any, keys: string[]) {
  const dup: any = {};
  for (const key in obj) {
    if (keys.indexOf(key) === -1) {
      dup[key] = obj[key];
    }
  }
  return dup;
}

export function createProtocolFilter(lowerbound: string, upperbound: string, stackids: string[]): IProtocolFilter {
  return {
    lowerbound: lowerbound,
    upperbound: upperbound,
    stackids: stackids,
  };
}

export function getEnvIds(environments: IServiceStack[]): string[] {
  const envids: string[] = [];
  environments.map((env) => envids.push(env.servicestackid));
  return envids;
}

export function getServiceStackById(environments: IServiceStack[], id: string): IServiceStack | undefined {
  return environments.find((element) => {
    return element.servicestackid === id;
  });
}

export function pad(num: number, size: number) {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

export function roleExists(role: string, roles: string[]): boolean {
  if (roles !== undefined) {
    return roles.some((v) => v === role);
  }
  return false;
}

export function showStatus(auth: AuthContextProps | null): boolean {
  return (
    auth != null &&
    auth.userData != null &&
    (roleExists("user", auth.userData.profile.roles) ||
      roleExists("poweruser", auth.userData.profile.roles) ||
      roleExists("admin", auth.userData.profile.roles))
  );
}
export function showProtocol(auth: AuthContextProps | null): boolean {
  return (
    auth != null &&
    auth.userData != null &&
    (roleExists("poweruser", auth.userData.profile.roles) || roleExists("admin", auth.userData.profile.roles))
  );
}
export function showAdministration(auth: AuthContextProps | null): boolean {
  return (
    auth != null &&
    auth.userData != null &&
    (roleExists("admin", auth.userData.profile.roles) || roleExists("admin", auth.userData.profile.roles))
  );
}

export function toApiUrl(sourceurl: string): string {
  console.log("determine apiurl from " + sourceurl);

  const urlObject = url.parse(sourceurl);

  if (urlObject.host?.startsWith("api.")) {
    let result = urlObject.protocol + "//api." + urlObject.host;

    if (urlObject.pathname !== null) {
      result = result + encodeURIComponent(urlObject.pathname);
    }
    return result;
  } else {
    return sourceurl;
  }
}

export function toClientId(sourceurl: string): string {
  console.log("determine client_id from " + sourceurl);

  const urlObject = url.parse(sourceurl);

  //from
  //"https://api.envmgr.986464814838.zeb-it.de/prod";
  //to
  //zeb-startstop-986464814838

  const id = urlObject.host?.match("envmgr\\.(\\d+)");
  if (id !== undefined && id !== null && id?.length > 1) {
    console.log("client_id = " + id[1]);
    return "zeb-startstop-" + id[1];
  }

  return "n.d.";
}

export function toHHMM(secnum: number): string {
  const millsec = moment.utc(moment.duration(secnum, "seconds").asMilliseconds());
  return parseInt(millsec.format("DDD")) - 1 + "d " + millsec.format("HH") + "h " + millsec.format("mm") + "m";
}
