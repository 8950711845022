import { Theme, makeStyles } from "@material-ui/core/styles";
import { zebStyles as parentZebStyles } from "../zebstyles";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  ...parentZebStyles(theme),

  formControlSelect: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(4),
  },
  button: {
    borderColor: "#0072C6",
    margin: theme.spacing(0),
    //backgroundColor: "#0072C6",
    //color: "White",
    color: theme.palette.grey[400],
    padding: theme.spacing(0),
  },
}));

export default function AddEnvironmentDialog(props: {
  label: string;
  callbackFunction: (environmentId: string, environmentName: string) => void;
}) {
  const [open, setOpen] = React.useState(false);

  const [environmentName, setEnvironmentName] = React.useState("");

  const classes = useStyles();

  const handleClickOpen = () => {
    setEnvironmentName("");
    setOpen(true);
  };

  const handleAdd = () => {
    props.callbackFunction(uuidv4(), environmentName);

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeEnvironmentName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnvironmentName(event.target.value);
  };

  return (
    <div>
      <Typography variant="subtitle1">{props.label}</Typography>
      <Tooltip title="Add Environment">
        <IconButton
          className={classes.button}
          size="small"
          color="primary"
          aria-label="add Environment"
          onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Environment</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Environment Name"
            type="text"
            fullWidth
            value={environmentName}
            onChange={handleChangeEnvironmentName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
