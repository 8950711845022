import { INSTANCETYPES, INSTANCETYPE_DEFAULT, instanceTypeValueByKey } from "../model";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { zebStyles as parentZebStyles } from "../zebstyles";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  ...parentZebStyles(theme),

  formControlSelect: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(4),
  },
  button: {
    borderColor: "#0072C6",
    margin: theme.spacing(0),
    //backgroundColor: "#0072C6",
    //color: "White",
    color: theme.palette.grey[400],
    padding: theme.spacing(0),
  },
  errorlabel: {
    fontSize: "15px",
    color: "red",
  },
}));

export default function AddInstanceDialog(props: {
  label: string;
  callbackFunction: (instanceName: string, instanceType: string) => void;
  callbackFunctionCheckServiceAlreadyAssigned: (nameofnewservice: string) => string | undefined;
}) {
  const [open, setOpen] = React.useState(false);

  const [instanceTypeSelectd, setInstanceTypeSelected] = React.useState(INSTANCETYPE_DEFAULT);
  const [instanceName, setInstanceName] = React.useState("");
  const [servicestacknameserviceavailable, setServicestacknameserviceavailable] = React.useState("");

  const classes = useStyles();

  const handleClickOpen = () => {
    setInstanceTypeSelected(INSTANCETYPE_DEFAULT);
    setServicestacknameserviceavailable("");
    setInstanceName("");
    setOpen(true);
  };

  const handleAdd = () => {
    //const servicestack = props.callbackFunctionCheckServiceAlreadyAssigned(instanceName);
    //  if (servicestack === undefined) {
    props.callbackFunction(instanceName, instanceTypeSelectd);
    setOpen(false);
    //  } else {
    //    setServicestacknameserviceavailable(servicestack);
    //  }
  };

  function ServiceAlreadyAssigned() {
    if (servicestacknameserviceavailable !== "") {
      return (
        <div>
          <FormLabel className={classes.errorlabel}>
            Service {instanceName} is already assigned in environment {servicestacknameserviceavailable}
          </FormLabel>
          <br />
        </div>
      );
    }
    return <div></div>;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInstanceName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstanceName(event.target.value);
    setServicestacknameserviceavailable("");
  };
  const handleChangeInstanceType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInstanceTypeSelected(event.target.value as INSTANCETYPES);
  };

  return (
    <div>
      <Typography variant="subtitle1">{props.label}</Typography>
      <Tooltip title="Add Service">
        <IconButton
          className={classes.button}
          size="small"
          edge="end"
          color="primary"
          aria-label="add Instance"
          onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add AWS Instance</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="servicename"
            label="Service Name"
            type="text"
            autoFocus
            //fullWidth
            style={{ width: 400 }}
            value={instanceName}
            onChange={handleChangeInstanceName}
          />

          <ServiceAlreadyAssigned></ServiceAlreadyAssigned>

          <FormControl className={classes.formControlSelect} fullWidth>
            <InputLabel id="label-type">Type</InputLabel>
            <Select
              labelId="label-type"
              id="select-type"
              label="Type"
              style={{ width: 400 }}
              value={instanceTypeSelectd}
              onChange={handleChangeInstanceType}>
              {Object.values(INSTANCETYPES).map((type, index) => (
                <MenuItem key={index} value={type}>
                  {instanceTypeValueByKey(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
