import { AuthProvider } from "oidc-react";
import { toClientId } from "./Utils";
import MainApp from "./MainApp";
import React from "react";

const oidcConfig = {
  onSignIn: async (user: any) => {
    //alert("You just signed in, congratz! Check out the console!");
    console.log(user);
    window.location.hash = "";
  },
  //authority: "https://idp.zeb-it.de/auth/realms/zeb/.well-known/openid-configuration",
  authority: "https://keycloak.dev.zeb-it.de/auth/realms/zeb/.well-known/openid-configuration",
  clientId: "zeb-startstop-734522026627",
  //clientId: toClientId(window.location.href),

  redirectUri: window.location.href,
  autoSignIn: false,
  scope: "profile email openid",
  responseType: "code",
};

export default function App() {
  console.log("ClientId: " + toClientId(window.location.href));
  return (
    <AuthProvider {...oidcConfig}>
      <div className="App">
        <MainApp></MainApp>
      </div>
    </AuthProvider>
  );
}
