import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import React from "react";
import StopIcon from "@material-ui/icons/Stop";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    button: {
      borderColor: "#0072C6",
      //width: 70,
    },
  })
);

export default function StopDialog(props: {
  type: string;
  index: number;
  name: string;
  callbackFunction: (index: number) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    props.callbackFunction(props.index);
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={props.type === "start" ? "Start Environment" : "Stop Environment"}>
        <IconButton aria-label="delete" onClick={handleClickOpen} className={classes.button} size="small">
          {props.type === "stop" ? <StopIcon /> : <PlayArrowIcon />}
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {" "}
          {props.type === "start" ? "Start Environment" : "Stop Environment"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.type === "start"
              ? 'Are you shure to start the evironment "' + props.name + '" ?'
              : 'Are you shure to stop the evironment "' + props.name + '" ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
