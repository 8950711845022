import { Theme } from "@material-ui/core/styles";

export const zebStyles = (theme: Theme) => {
  return {
    iconButton: {
      color: "#0072C6",
    },
    iconButtonWhite: {
      color: "white",
    },
    iconButtonGrey: {
      borderColor: "#0072C6",
      margin: theme.spacing(0),
      //backgroundColor: "#0072C6",
      //color: "White",
      color: theme.palette.grey[500],
      padding: theme.spacing(0),
    },
    backgroundGrey: {
      backgroundColor: "#F3F3F3",
    },
    backgroundBlue: {
      backgroundColor: "#0072C6",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logo: {
      marginRight: theme.spacing(2),
      color: "White",
    },
    iconSecondaryColor: {
      color: "White",
    },
    tabs: {
      backgroundColor: "#F3F3F3",
      color: "#666666",
      indicatorColor: "#0072C6",
    },
  };
};
