import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import useAPIError from "./useAPIError";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
    },
    extendedIcon: {
      marginRight: theme.spacing(0),
    },
    button: {
      borderColor: "#0072C6",
      margin: theme.spacing(0),
      //backgroundColor: "#0072C6",
      //color: "White",
      color: theme.palette.grey[500],
      padding: theme.spacing(0),
    },
  })
);

export default function ErrorDialog() {
  const classes = useStyles();
  const error = useAPIError();

  const handleOk = () => {
    error.removeError();
  };

  return (
    <div>
      <Dialog open={error.showDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{(error.type = "error" ? "Error" : "Warning")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{error.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
